<template>
  <div
    class="container-fluid pt-4"
    id="floor_plan_editor"
    @mousedown="onGlobalMouseDown"
  >
    <div class="d-flex justify-content-between">
      <div
        class="d-flex align-items-start align-items-md-end flex-column flex-md-row"
        v-if="buildingID != null"
      >
        <router-link
          :to="{
            name: 'Floor Plan',
            params: { building_id: buildingID, floor_id: floorID }
          }"
          v-slot="{ href, route, navigate }"
          custom
        >
          <a :href="href" @click="navigate" class="mr-2"
            ><i class="fa fa-chevron-left mr-1"></i>Back</a
          >
        </router-link>
        <h1 class="mb-0 mr-2">
          Manage Floorplan: {{ buildingName }} - {{ floorName }} Editor
        </h1>
      </div>
    </div>
    <div class="d-flex justify-content-between mt-3">
      <div
        class="d-flex"
        v-if="buildingID != null && activeFloorplanID !== null"
      >
        <h2 class="mr-2 mt-auto">Currently Viewing:</h2>

        <div class="d-flex" v-if="buildingID != null">
          <!-- Using components -->
          <b-input-group id="input-group-floorplan" label-for="input-floorplan">
            <b-form-select
              class="floorplan-dropdown"
              id="input-floorplan"
              size="sm"
              v-model="activeFloorplanID"
              @change="listenChangeActiveFloorplan"
              :disabled="isEditMode"
              required
              aria-placeholder="Select a floorplan"
            >
              <b-form-select-option class="" :value="null" disabled
                >Select a floorplan</b-form-select-option
              >
              <b-form-select-option
                class=""
                v-for="floorplan in floorPlansList"
                :value="floorplan.id"
                :key="floorplan.id"
                >{{ floorplan.name }}
              </b-form-select-option>
            </b-form-select>

            <b-input-group-append>
              <b-button
                :class="[isEditMode ? 'disabled' : '']"
                class="px-2"
                variant="success"
                size="sm"
                v-b-modal.form-add-floorplan
              >
                <i class="fa fa-plus"></i> Add
              </b-button>

              <b-button
                v-if="activeFloorplanID !== null"
                :class="[isEditMode ? 'disabled' : '']"
                class="px-2"
                variant="primary"
                size="sm"
                v-b-modal.form-update-floorplan
              >
                <i class="fa fa-edit"></i> Edit
              </b-button>

              <b-button
                v-if="activeFloorplanID !== null"
                :class="[isEditMode ? 'disabled' : '']"
                class="px-2"
                variant="danger"
                size="sm"
                @click="confirmDeleteFloorPlan"
              >
                <i class="fa fa-times"></i> Delete
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <div class="d-flex" v-if="buildingID != null">
        <div class="d-flex align-items-center">
          <span class="mr-3" v-if="lastSavedTime.original"
            >Last saved: {{ lastSavedTime.formatted }}</span
          >
          <div
            class="mode-toggle"
            :data-mode="[isEditMode ? 'edit' : 'view']"
            v-if="activeFloorplanID !== null"
          >
            <b-form-checkbox
              id="editModeToggle"
              v-model="isEditMode"
              :value="true"
              :unchecked-value="false"
              @change="toggleFloorPlanLayoutEditMode()"
              switch
            >
            </b-form-checkbox>
            <span @click="isEditMode = !isEditMode" class="mode-toggle-text">{{
              isEditMode ? "Edit Mode" : "View Mode"
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row mt-2 floorplan-wrapper"
      @click="
        islistenReleaseActiveMoveableElementId
          ? releaseActiveMoveableElement()
          : ''
      "
    >
      <div v-if="activeFloorplanID === null" class="col-12 text-center mt-5">
        <h2>You don't have any created floorplan yet.</h2>
        <b-button
          class="text-center btn-action btn-sm"
          variant="success"
          size="sm"
          v-b-modal.form-add-floorplan
          >Create Now!
        </b-button>
      </div>
      <div class="col-9">
        <div
          class="floorplan-img-container position-relative h-100"
          ref="floorplanImageContainer"
        >
          <div
            v-for="device in plottedFloorDevicesList"
            :key="device.id"
            @click="setActiveMoveableId(device.id)"
            @mouseup="checkIsRemoveDevice()"
          >
            <Moveable
              v-if="device.type == 'camera'"
              :id="device.id + '_section'"
              class="floorplan-device-option position-absolute floor-device-icon floor-device-icon-camera mr-2"
              ref="moveable"
              :class="[
                device.id + '_section',
                device.pos != null ? 'plotted' : '',
                isEditMode &&
                (!activeMoveableElementId ||
                  activeMoveableElementId === device.id)
                  ? 'cursor-pointer'
                  : 'opacity-50'
              ]"
              v-bind="[
                activeMoveableElementId === device.id ? moveableSetting : {}
              ]"
              @drag="handleDrag"
              @resize="handleResize"
              @scale="handleScale"
              @rotate="handleRotate"
              @warp="handleWarp"
              v-b-tooltip.hover
              :title="device.ip"
              :style="device.current_coords"
            >
              <i class="fa fa-video"></i>
            </Moveable>

            <Moveable
              v-if="device.type == 'door'"
              :key="device.id"
              :id="device.id + '_section'"
              class="floorplan-device-option position-absolute floor-device-icon floor-device-icon-door mr-2"
              ref="moveable"
              :class="[
                device.id + '_section',
                device.pos != null ? 'plotted' : '',
                isEditMode &&
                (!activeMoveableElementId ||
                  activeMoveableElementId === device.id)
                  ? 'cursor-pointer'
                  : 'opacity-50'
              ]"
              v-bind="[
                activeMoveableElementId === device.id ? moveableSetting : {}
              ]"
              @drag="handleDrag"
              @resize="handleResize"
              @scale="handleScale"
              @rotate="handleRotate"
              @warp="handleWarp"
              v-b-tooltip.hover
              :title="device.name"
              :style="device.current_coords"
            >
              <i class="fa fa-door-closed"></i>
            </Moveable>

            <Moveable
              v-if="device.type == 'reader'"
              :key="device.id"
              :id="device.id + '_section'"
              class="floorplan-device-option position-absolute floor-device-icon floor-device-icon-door mr-2"
              ref="moveable"
              :class="[
                device.id + '_section',
                device.pos != null ? 'plotted' : '',
                isEditMode &&
                (!activeMoveableElementId ||
                  activeMoveableElementId === device.id)
                  ? 'cursor-pointer'
                  : 'opacity-50'
              ]"
              v-bind="[
                activeMoveableElementId === device.id ? moveableSetting : {}
              ]"
              @drag="handleDrag"
              @resize="handleResize"
              @scale="handleScale"
              @rotate="handleRotate"
              @warp="handleWarp"
              v-b-tooltip.hover
              :title="device.id"
              :style="device.current_coords"
            >
              <i class="fa fa-registered"></i>
            </Moveable>

            <Moveable
              v-if="device.type == 'input'"
              :key="device.id"
              :id="device.id + '_section'"
              class="floorplan-device-option position-absolute floor-device-icon floor-device-icon-door mr-2"
              ref="moveable"
              :class="[
                device.id + '_section',
                device.pos != null ? 'plotted' : '',
                isEditMode &&
                (!activeMoveableElementId ||
                  activeMoveableElementId === device.id)
                  ? 'cursor-pointer'
                  : 'opacity-50'
              ]"
              v-bind="[
                activeMoveableElementId === device.id ? moveableSetting : {}
              ]"
              @drag="handleDrag"
              @resize="handleResize"
              @scale="handleScale"
              @rotate="handleRotate"
              @warp="handleWarp"
              v-b-tooltip.hover
              :title="device.id"
              :style="device.current_coords"
            >
              <i class="fa fa-info"></i>
            </Moveable>

            <!--                        {{ device.ip }}<span v-if="device.pos != null" class="ml-1">(plotted)</span>-->
          </div>

          <img
            :src="activeFloorplanObj.image_b64"
            class="h-100 floorplan-image"
            ref="floorplanImage"
          />
        </div>
      </div>
      <div
        v-if="activeFloorplanID !== null"
        class="col-3 unplotted-device-section"
        ref="unplottedDevicesContainer"
        @mouseover="hoverDropzone = true"
        @mouseleave="hoverDropzone = false"
        :class="[
          activeMoveableElementId && hoverDropzone && !isMoveableUnplotted
            ? 'dropzone'
            : ''
        ]"
      >
        <h2>Unplotted Devices in Floor</h2>
        <div
          v-for="device in unplottedFloorDevicesList"
          :key="device.id"
          @click="
            setActiveMoveableId(device.id);
            isMoveableUnplotted = true;
          "
          @mouseup="checkIsRemoveDevice()"
          class="mb-2 device-container"
          :class="[
            activeMoveableElementId === device.id
              ? 'device-container-selected'
              : ''
          ]"
        >
          <!-- class="mb-4 row" -->
          <!--                    <Moveable-->
          <!--                            ref="moveable"-->
          <!--                            :class="[isEditMode === true ? 'cursor-pointer' : '']"-->
          <!--                            v-bind="[isEditMode === true ? moveableSetting : {}]"-->
          <!--                            @drag="handleDrag"-->
          <!--                            @resize="handleResize"-->
          <!--                            @scale="handleScale"-->
          <!--                            @rotate="handleRotate"-->
          <!--                            @warp="handleWarp"-->
          <!--                    >-->
          <!--                        <span>Vue Moveable</span>-->
          <!--                    </Moveable>-->
          <!-- <div class="col-12"> -->
          <div class="d-flex align-items-center">
            <Moveable
              v-if="device.type == 'camera'"
              :id="device.id + '_section'"
              class="floorplan-device-option floor-device-icon floor-device-icon-camera mr-2 unplotted-device-icon"
              ref="moveable"
              :class="[
                device.id + '_section',
                device.pos != null ? 'plotted' : '',
                isEditMode &&
                (!activeMoveableElementId ||
                  activeMoveableElementId === device.id)
                  ? 'cursor-pointer'
                  : 'opacity-50'
              ]"
              v-bind="[
                activeMoveableElementId === device.id ? moveableSetting : {}
              ]"
              @drag="handleDrag"
              @resize="handleResize"
              @scale="handleScale"
              @rotate="handleRotate"
              @warp="handleWarp"
              v-b-tooltip.hover
              :title="device.name"
            >
              <i class="fa fa-video"></i>
            </Moveable>

            <Moveable
              v-if="device.type == 'door'"
              :id="device.id + '_section'"
              class="floorplan-device-option floor-device-icon floor-device-icon-door mr-2 unplotted-device-icon"
              ref="moveable"
              :class="[
                device.id + '_section',
                device.pos != null ? 'plotted' : '',
                isEditMode &&
                (!activeMoveableElementId ||
                  activeMoveableElementId === device.id)
                  ? 'cursor-pointer'
                  : 'opacity-50'
              ]"
              v-bind="[
                activeMoveableElementId === device.id ? moveableSetting : {}
              ]"
              @drag="handleDrag"
              @resize="handleResize"
              @scale="handleScale"
              @rotate="handleRotate"
              @warp="handleWarp"
              v-b-tooltip.hover
              :title="device.name"
            >
              <i class="fa fa-door-closed"></i>
            </Moveable>

            <Moveable
              v-if="device.type == 'reader'"
              :id="device.id + '_section'"
              class="floorplan-device-option floor-device-icon floor-device-icon-door mr-2 unplotted-device-icon"
              ref="moveable"
              :class="[
                device.id + '_section',
                device.pos != null ? 'plotted' : '',
                isEditMode &&
                (!activeMoveableElementId ||
                  activeMoveableElementId === device.id)
                  ? 'cursor-pointer'
                  : 'opacity-50'
              ]"
              v-bind="[
                activeMoveableElementId === device.id ? moveableSetting : {}
              ]"
              @drag="handleDrag"
              @resize="handleResize"
              @scale="handleScale"
              @rotate="handleRotate"
              @warp="handleWarp"
              v-b-tooltip.hover
              :title="device.name"
            >
              <i class="fa fa-registered"></i>
            </Moveable>

            <Moveable
              v-if="device.type == 'input'"
              :id="device.id + '_section'"
              class="floorplan-device-option floor-device-icon floor-device-icon-door mr-2 unplotted-device-icon"
              ref="moveable"
              :class="[
                device.id + '_section',
                device.pos != null ? 'plotted' : '',
                isEditMode &&
                (!activeMoveableElementId ||
                  activeMoveableElementId === device.id)
                  ? 'cursor-pointer'
                  : 'opacity-50'
              ]"
              v-bind="[
                activeMoveableElementId === device.id ? moveableSetting : {}
              ]"
              @drag="handleDrag"
              @resize="handleResize"
              @scale="handleScale"
              @rotate="handleRotate"
              @warp="handleWarp"
              v-b-tooltip.hover
              :title="device.name"
            >
              <i class="fa fa-info"></i>
            </Moveable>

            <div v-show="activeMoveableElementLabelClass(device.id)">
              {{ device.name
              }}<span v-if="device.pos != null" class="ml-1 ]">(plotted)</span>
            </div>
          </div>
        </div>
        <div
          v-if="
            activeMoveableElementId && hoverDropzone && !isMoveableUnplotted
          "
          class="position-relative text-center dropzone-text"
        >
          <h2>Drop device here to return device as unplotted</h2>
        </div>
      </div>
    </div>

    <b-modal
      id="form-add-floorplan"
      style="z-index: 1100"
      title="Add Floor Plan"
      ref="formAddFloorplan"
      centered
      hide-footer
    >
      <b-form @submit="onSubmitFormAddFloorplan" class="mt-2">
        <b-form-group
          id="input-group-name"
          label="Name:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="formAddFloorplan.name"
            type="text"
            placeholder="Enter floor plan name"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-floorplan"
          label="Floor Plan Image:"
          label-for="input-floorplan"
        >
          <b-form-file
            v-model="formAddFloorplan.floorplan"
            :state="Boolean(formAddFloorplan.floorplan)"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept="image/jpeg, image/png, image/gif"
          ></b-form-file>
          <div class="mt-3">
            Selected file:
            {{
              formAddFloorplan.floorplan ? formAddFloorplan.floorplan.name : ""
            }}
          </div>
        </b-form-group>

        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>

    <b-modal
      id="form-update-floorplan"
      style="z-index: 1100"
      title="Update Floor Plan"
      ref="formUpdateFloorplan"
      centered
      hide-footer
    >
      <b-form @submit="onSubmitFormUpdateFloorplan" class="mt-2">
        <b-form-group
          id="input-group-name"
          label="Name:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="formUpdateFloorplan.name"
            type="text"
            placeholder="Enter floor plan name"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-floorplan"
          label="Floor Plan Image:"
          label-for="input-floorplan"
        >
          <b-form-file
            v-model="formUpdateFloorplan.floorplan"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept="image/jpeg, image/png, image/gif"
          ></b-form-file>
          <div class="mt-3">
            Selected file:
            {{
              formUpdateFloorplan.floorplan
                ? formUpdateFloorplan.floorplan.name
                : ""
            }}
          </div>
        </b-form-group>

        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
import Moveable from "vue-moveable";
import Vue from "vue";
import moment from "moment";

let utils = require("@/assets/global/js/utils.js");

export default {
  data() {
    return {
      buildingID: null,
      buildingName: null,
      floorID: null,
      floorName: null,
      floorData: {},
      floorDevicesList: [],
      unplottedFloorDevicesList: [],
      plottedFloorDevicesList: {},
      cameraList: [],
      controllerList: [],
      doorList: [],
      readerList: [],
      inputList: [],
      floorPlansList: [],
      formAddFloorplan: {
        name: null,
        floorplan: null
      },
      formUpdateFloorplan: {
        name: null,
        floorplan: null
      },
      hoverDropzone: false,
      activeMoveableElementId: null,
      activeMoveableElement: {},
      activeFloorplanID: null,
      lastSavedTime: {
        original: null,
        formatted: null
      },
      activeFloorplanObj: {},
      isEditMode: false,
      islistenReleaseActiveMoveableElementId: false,
      isMoveableOnDrag: false,
      isMoveableUnplotted: false,
      isMoveableOutsideDropzone: false,
      moveableSetting: {
        draggable: true,
        throttleDrag: 1,
        resizable: true,
        throttleResize: 1,
        keepRatio: true,
        scalable: true,
        throttleScale: 0.01,
        rotatable: true,
        throttleRotate: 0.2,
        pinchable: true,
        origin: false
      },
      unplottedContainer: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    };
  },
  components: {
    Moveable
  },
  computed: {
    ...mapGetters({
      isDevicesInitiated: "psim/isDevicesInitiated",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  watch: {
    isDevicesInitiated: {
      handler: function (n, o) {
        if (n == true) {
          this.initView();
        }
      }
    }
  },
  mounted: function () {
    this.buildingID = parseInt(this.$route.params.building_id);
    this.floorID = parseInt(this.$route.params.floor_id);

    if (this.isDevicesInitiated == true) {
      this.initView();
    }

    window.setInterval(() => {
      if (this.lastSavedTime.original) {
        this.refreshLastSavedTime();
      }
    }, 60000);
  },
  methods: {
    initView: function () {
      // console.log("Viewing SettingsFloorplan - FloorplanEditor");
      var $this = this;
      var building_obj = this.$store.getters["psim/getBuilding"](
        $this.buildingID
      );
      var floor_obj = this.$store.getters["psim/getFloor"](
        $this.buildingID,
        $this.floorID
      );
      $this.floorName = floor_obj.name;
      $this.floorData = floor_obj;
      $this.buildingName = building_obj.name;
      $this.$parent.pageTitle = "Floor Plan Editor";

      $this.fetchDevices();
      $this.fetchFloorPlans();

      var unplotted_container_pos =
        $this.$refs.unplottedDevicesContainer != undefined
          ? $this.$refs.unplottedDevicesContainer.getBoundingClientRect()
          : {
              x: 0,
              y: 0,
              width: 0,
              height: 0,
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            };
      var unplotted_container_left = unplotted_container_pos.x;
      var unplotted_container_right =
        unplotted_container_pos.x + unplotted_container_pos.width;
      var unplotted_container_top = unplotted_container_pos.y;
      var unplotted_container_bottom =
        unplotted_container_pos.y + unplotted_container_pos.height;
      $this.unplottedContainer = {
        left: unplotted_container_left,
        right: unplotted_container_right,
        top: unplotted_container_top,
        bottom: unplotted_container_bottom
      };
    },

    fetchDevices: async function () {
      var $this = this;
      var API_URL = $this.getAPIServerURL + "/api/monitorservice/status/";
      var controllerDetailsList = [];
      const client = $this.$root.getAjaxFetchClient();
      let mdata = await client.getRequest(API_URL);
      let category_result = null;
      for (var service_url in mdata["result"]) {
        try {
          category_result = mdata["result"][service_url]["result"]["DEV_CTRL"];
        } catch (err) {
          //
        }
        if (category_result != null) {
          for (var i in category_result) {
            var device_url = i;
            var device_data = category_result[i];
            var device_ip = new URL(device_url).host;
            if (device_data.details != undefined) {
              if (device_data.details.ip != undefined) {
                device_ip = device_data.details.ip;
              }
            }
            var device_result = {
              id: device_url,
              ip: device_ip,
              type: "controller",
              status: device_data["status"],
              data: {
                server_url:
                  device_data["monitor_url"] == undefined
                    ? null
                    : device_data["monitor_url"],
                details:
                  device_data["details"] == undefined
                    ? null
                    : device_data["details"],
                meta:
                  device_data["meta"] == undefined ? null : device_data["meta"]
              }
            };
            controllerDetailsList.push(device_result);
          }
        }
      }

      $this.floorDevicesList = [];
      if ($this.buildingID != null) {
        var camera_list = this.$store.getters["psim/getCameras"]({
          building__id: $this.buildingID,
          floor__id: $this.floorID
        });
        var controller_list = this.$store.getters["psim/getControllers"]({
          building__id: $this.buildingID,
          floor__id: $this.floorID
        });

        let controller_list_with_details = [];

        // find all controller & update with details
        for (let i = 0; i < controller_list.length; i++) {
          let foundController = controllerDetailsList.find((o) => {
            return o.id === controller_list[i].id;
          });
          if (foundController !== undefined) {
            controller_list_with_details.push({
              ...controller_list[i],
              ...foundController
            });
          }
        }

        // get door, reader, input from controller details

        let door_list = [];
        let reader_list = [];
        let input_list = [];

        for (let i = 0; i < controller_list_with_details.length; i++) {
          let controller = controller_list_with_details[i];

          if (controller.data.details.doors.length > 0) {
            door_list = this.findControllerSubDevicesByType(
              controller,
              "door",
              controller.data.details.doors
            );
          }
          if (controller.data.details.readers.length > 0) {
            reader_list = this.findControllerSubDevicesByType(
              controller,
              "reader",
              controller.data.details.readers
            );
          }
          if (controller.data.details.inputs.length > 0) {
            input_list = this.findControllerSubDevicesByType(
              controller,
              "input",
              controller.data.details.inputs
            );
          }
        }

        // console.log("door_list");
        // console.log(door_list);
        // console.log("reader_list");
        // console.log(reader_list);
        // console.log("input_list");
        // console.log(input_list);

        $this.floorDevicesList = $this.floorDevicesList.concat(camera_list);
        $this.floorDevicesList = $this.floorDevicesList.concat(door_list);
        $this.floorDevicesList = $this.floorDevicesList.concat(reader_list);
        $this.floorDevicesList = $this.floorDevicesList.concat(input_list);

        $this.floorDevicesList.forEach((value, key) => {
          $this.floorDevicesList[key].id = value.id;
        });
        //
        // console.log("$this.floorDevicesList");
        // console.log($this.floorDevicesList);

        // $this.unplottedFloorDevicesList = $this.floorDevicesList;
        $this.cameraList = camera_list;
        $this.controllerList = controller_list_with_details;
        $this.doorList = door_list;
        $this.readerList = reader_list;
        $this.inputList = input_list;
        // console.log(camera_list);
      }
      // console.log("Fetched Device List");
      // end fetchDevices
    },
    generateDeviceId(controller_url, type, no) {
      return controller_url + "_" + type + "_" + no;
    },
    generateDeviceName(controller_name, type, no) {
      return controller_name + " (" + type + " " + no + ")";
    },
    findControllerSubDevicesByType(controller, type, data) {
      let subDeviceList = [];
      for (let i = 0; i < data.length; i++) {
        let subDevice = data[i];
        subDeviceList.push({
          ...subDevice,
          building: controller.building,
          floor: controller.floor,
          id: this.generateDeviceId(
            controller.id,
            type,
            subDevice[type + "_no"]
          ),
          name: this.generateDeviceName(
            controller.name,
            type,
            subDevice[type + "_no"]
          ),
          ip: controller.ip,
          type: type
        });
      }
      return subDeviceList;
    },
    fetchFloorPlans: function (floor_id) {
      var $this = this;
      var API_URL =
        $this.getAPIServerURL +
        "/floorplans/buildings/" +
        $this.buildingID +
        "/floors/" +
        $this.floorID +
        "/floorplans/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          // console.log(data);
          if (data.result != undefined) {
            $this.floorPlansList = data.result;
            $this.$store.dispatch("psim/updateFloor", {
              building_id: $this.buildingID,
              floor_id: floor_id,
              data: { floorplans: $this.floorPlansList }
            });
            if (
              $this.activeFloorplanID == null &&
              $this.floorPlansList.length > 0
            ) {
              // console.log('set default floor plan: ' + $this.floorPlansList[0]["id"])
              $this.changeActiveFloorplan($this.floorPlansList[0]["id"]);
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    getUnplottedFloorDevices: function () {
      var $this = this;
      var plottedDeviceId = [];

      Object.entries($this.plottedFloorDevicesList).forEach(
        ([key, plottedDevice]) => {
          plottedDeviceId.push(plottedDevice.id);
        }
      );
      Object.entries($this.floorDevicesList).forEach(([key, device]) => {
        if (!plottedDeviceId.includes(device.id)) {
          $this.unplottedFloorDevicesList.push(device);
        }
      });
    },
    onSubmitFormAddFloorplan: function (e) {
      e.preventDefault();
      var $this = this;
      if ($this.formAddFloorplan.floorplan != null) {
        var form_data = new FormData();
        form_data.append(
          "floorplan",
          $this.formAddFloorplan.floorplan,
          $this.formAddFloorplan.floorplan.name
        );
        form_data.append("name", String($this.formAddFloorplan.name));

        var API_URL =
          $this.getAPIServerURL +
          "/floorplans/buildings/" +
          $this.buildingID +
          "/floors/" +
          $this.floorID +
          "/floorplans/";
        const client = $this.$root.getAjaxFetchClient();
        client
          .postRequest(API_URL, form_data)
          .then((data) => {
            // $this.fetchFloorPlan();
            $this.fetchFloorPlans();
            // $this.resetFormAddFloorplan();
            $this.changeActiveFloorplan(data.id);
            $this.$refs.formAddFloorplan.hide();
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Floor Plan has been uploaded successfully",
              message_type: "success"
            });
          })
          .catch((err) => {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err.detail,
              message_type: "danger"
            });
            if (err.status == 401) {
              $this.$store.dispatch("session/logoutSession");
            }
          });
      }
      $this.fetchFloorPlans();
    },
    onSubmitFormUpdateFloorplan: function (e) {
      e.preventDefault();
      var $this = this;
      if (
        $this.formUpdateFloorplan.floorplan != null ||
        $this.formUpdateFloorplan.name != null
      ) {
        var form_data = new FormData();
        if ($this.formUpdateFloorplan.floorplan) {
          form_data.append(
            "floorplan",
            $this.formUpdateFloorplan.floorplan,
            $this.formUpdateFloorplan.floorplan.name
          );
        }
        form_data.append("name", String($this.formUpdateFloorplan.name));

        var API_URL =
          $this.getAPIServerURL +
          "/floorplans/buildings/" +
          $this.buildingID +
          "/floors/" +
          $this.floorID +
          "/floorplans/" +
          $this.activeFloorplanID +
          "/";
        const client = $this.$root.getAjaxFetchClient();
        client
          .putRequest(API_URL, form_data)
          .then((data) => {
            // console.log(data.success)
            $this.fetchFloorPlans();
            // $this.resetFormUpdateFloorplan();
            $this.changeActiveFloorplan($this.activeFloorplanID);
            $this.$refs.formUpdateFloorplan.hide();
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Floor Plan has been updated successfully",
              message_type: "success"
            });
          })
          .catch((err) => {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err.detail,
              message_type: "danger"
            });
            if (err.status == 401) {
              $this.$store.dispatch("session/logoutSession");
            }
          });
      }
      $this.fetchFloorPlans();
    },
    updateFloorplanDevices: function (data) {
      // var form_data = JSON.parse(JSON.stringify(data));
      var $this = this;
      var API_URL =
        $this.getAPIServerURL +
        "/floorplans/buildings/" +
        $this.buildingID +
        "/floors/" +
        $this.floorID +
        "/floorplans/" +
        $this.activeFloorplanID +
        "/devices/";
      const client = $this.$root.getAjaxFetchClient();
      // console.log(data)
      client
        .putRequest(API_URL, data)
        .then((data) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Floor Plan has been updated successfully",
            message_type: "success"
          });
          $this.lastSavedTime.original = moment();
          $this.refreshLastSavedTime();
          $this.updateActiveFloorplan();
          // $this.fetchFloorPlan();
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          if (err.status == 401) {
            $this.$store.dispatch("session/logoutSession");
          }
        });
      // $this.fetchFloorPlans();
    },
    refreshLastSavedTime: function () {
      var $this = this;
      $this.lastSavedTime.formatted = $this.lastSavedTime.original.fromNow();
    },
    updateActiveFloorplan: function () {
      var $this = this;
      var API_URL =
        $this.getAPIServerURL +
        "/floorplans/buildings/" +
        $this.buildingID +
        "/floors/" +
        $this.floorID +
        "/floorplans/" +
        $this.activeFloorplanID +
        "/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          var building_obj = $this.$store.getters["psim/getBuilding"](
            $this.buildingID
          );
          var floor_obj = $this.$store.getters["psim/getFloor"](
            $this.buildingID,
            $this.floorID
          );

          var floorplan_image_b64 = utils.cleanBase64ImgSrc(
            data.image_b64,
            data.mime_type
          );
          $this.floorplan_image = floorplan_image_b64;

          for (var d in data.devices) {
            var device_id = d;
            var device_data = data.devices[d];
            device_data["building"] = {
              id: building_obj.id,
              name: building_obj.name
            };
            device_data["floor"] = { id: floor_obj.id, name: floor_obj.name };
          }

          this.$store.dispatch("psim/setActiveFloorplan", {
            id: data.id,
            name: data.name,
            devices: data.devices,
            image: floorplan_image_b64
          });
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          if (err.status == 401) {
            $this.$store.dispatch("session/logoutSession");
          }
        });
    },
    changeActiveFloorplan: function (newActiveFloorPlanID) {
      var $this = this;
      $this.activeFloorplanID = newActiveFloorPlanID;
      // console.log(newActiveFloorPlanID)
      var API_URL =
        $this.getAPIServerURL +
        "/floorplans/buildings/" +
        $this.buildingID +
        "/floors/" +
        $this.floorID +
        "/floorplans/" +
        newActiveFloorPlanID +
        "/";
      // console.log('fetching')
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          // console.log(data.image_b64)
          $this.activeFloorplanObj = {
            id: data.id,
            name: data.name,
            devices: data.devices,
            data: data.data,
            image_b64: utils.cleanBase64ImgSrc(data.image_b64)
          };
          $this.formUpdateFloorplan.name = data.name;

          // var plottedDeviceArray = [];
          // Object.entries(data.devices).forEach(([key, plottedDevice]) => {
          //     console.log(plottedDevice)
          //     plottedDeviceArray[plottedDevice.device_pk] = plottedDevice.coords;
          // })
          $this.unplottedFloorDevicesList = [];
          $this.plottedFloorDevicesList = {};

          setTimeout(() => {
            $this.floorDevicesList.forEach((floorDevice, key) => {
              if (
                data.devices[floorDevice.id] &&
                data.devices[floorDevice.id] !== undefined &&
                data.devices[floorDevice.id] !== null
              ) {
                floorDevice["coords"] = data.devices[floorDevice.id]["coords"];
                floorDevice["current_coords"] =
                  $this.getFloorDeviceCurrentCoordinates(floorDevice["coords"]);

                // $this.plottedFloorDevicesList.push();
                $this.$set(
                  $this.plottedFloorDevicesList,
                  floorDevice.id,
                  floorDevice
                );
              }
            });

            $this.getUnplottedFloorDevices();
          }, 100);

          // console.log($this.plottedFloorDevicesList)
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          if (err.status == 401) {
            $this.$store.dispatch("session/logoutSession");
          }
        });
    },
    getFloorDeviceCurrentCoordinates: function (
      deviceCoords,
      isRecalculatePosition = true
    ) {
      var $this = this;
      var $floorPlanImageContainer =
        document.getElementsByClassName("floorplan-image")[0];
      var finalCoords = JSON.parse(JSON.stringify(deviceCoords));

      if (isRecalculatePosition) {
        var original_coords = {};
        var current_coords = {};
        current_coords.width =
          $floorPlanImageContainer.getBoundingClientRect().width;
        current_coords.height =
          $floorPlanImageContainer.getBoundingClientRect().height;
        original_coords.width = deviceCoords["width"];
        original_coords.height = deviceCoords["height"];
        original_coords.left = deviceCoords["left"];
        original_coords.top = deviceCoords["top"];
        original_coords.icon_width = deviceCoords["icon_width"];
        original_coords.icon_height = deviceCoords["icon_height"];
        original_coords.rotate = deviceCoords["rotate"];
        current_coords.left =
          (original_coords.left / original_coords.width) * current_coords.width;
        current_coords.top =
          (original_coords.top / original_coords.height) *
          current_coords.height;
        current_coords.icon_width =
          (original_coords.icon_width / original_coords.width) *
          current_coords.width;
        current_coords.icon_height =
          (original_coords.icon_height / original_coords.height) *
          current_coords.height;

        var rotation = String(original_coords.rotate);
        rotation = rotation.replace("rotate(", "");
        rotation = rotation.replace("deg)", "");

        // console.log(original_coords)
        // console.log(current_coords)

        finalCoords = {
          left: current_coords.left,
          top: current_coords.top,
          width: current_coords.icon_width,
          height: current_coords.icon_height,
          icon_width: current_coords.icon_width,
          icon_height: current_coords.icon_height,
          rotate: rotation
        };
      }

      finalCoords["left"] += "px";
      finalCoords["top"] += "px";
      finalCoords["width"] = finalCoords["icon_width"] + "px";
      finalCoords["height"] = finalCoords["icon_height"] + "px";
      finalCoords["transform"] = "rotate(" + finalCoords["rotate"] + "deg)";
      finalCoords["position"] = "absolute";

      return finalCoords;
    },
    checkIsRemoveDevice: function () {
      var $this = this;
      if (
        $this.islistenReleaseActiveMoveableElementId &&
        !$this.isWithinFloorplanImage()
      ) {
        $this.removeDeviceFromFloorplan();
      }
    },
    getActiveMoveableCoordinates: function () {
      var $this = this;
      var $currentMoveableElement = document.getElementsByClassName(
        $this.activeMoveableElementId + "_section"
      )[0];
      var $floorPlanImageContainer = $this.$refs.floorplanImage;

      var parentPos = $floorPlanImageContainer.getBoundingClientRect();
      var childPos = $currentMoveableElement.getBoundingClientRect();
      var relativePos = {};
      relativePos.top = childPos.top - parentPos.top;
      relativePos.left = childPos.left - parentPos.left;

      var coords = {};
      coords.top = relativePos.top;
      coords.left = relativePos.left;
      coords.width =
        $floorPlanImageContainer.getBoundingClientRect().width ||
        $floorPlanImageContainer.width;
      coords.height =
        $floorPlanImageContainer.getBoundingClientRect().height ||
        $floorPlanImageContainer.height;
      coords.icon_width = childPos.width;
      coords.icon_height = childPos.height;
      coords.rotate = $this.getElementRotation(
        $this.activeMoveableElementId + "_section"
      );

      let posX = 0;
      let posY = 0;
      posX = ((coords.left + coords.icon_width / 2) / coords.width) * 100;
      posY = ((coords.top + coords.icon_height / 2) / coords.height) * 100;
      coords.posX = posX;
      coords.posY = posY;

      return coords;
    },
    getElementRotation: function (elid) {
      var $this = this;
      var el = document.getElementsByClassName(elid)[0];
      var st = window.getComputedStyle(el, null);
      var tr =
        st.getPropertyValue("-webkit-transform") ||
        st.getPropertyValue("-moz-transform") ||
        st.getPropertyValue("-ms-transform") ||
        st.getPropertyValue("-o-transform") ||
        st.getPropertyValue("transform") ||
        "fail...";

      if (tr !== "none") {
        // console.log('Matrix: ' + tr);

        var values = tr.split("(")[1];
        values = values.split(")")[0];
        values = values.split(",");
        var a = values[0];
        var b = values[1];
        var c = values[2];
        var d = values[3];

        var scale = Math.sqrt(a * a + b * b);

        // First option, don't check for negative result
        // Second, check for the negative result
        /**/
        var radians = Math.atan2(b, a);
        var angle = Math.round(radians * (180 / Math.PI));
        /*/
        var radians = Math.atan2(b, a);
        if ( radians < 0 ) {
          radians += (2 * Math.PI);
        }
        var angle = Math.round( radians * (180/Math.PI));
        /**/
      } else {
        angle = 0;
      }

      return angle;
    },
    releaseActiveMoveableElement: function () {
      var $this = this;

      if ($this.isMoveableOnDrag) {
        return;
      }
      if ($this.isWithinFloorplanImage()) {
        // TODO Call API to save data and remove this device from device_list if within
        // console.log($this.unplottedFloorDevicesList)
        let i = $this.unplottedFloorDevicesList
          .map((item) => item.id)
          .indexOf($this.activeMoveableElementId);
        if (i >= 0) {
          $this.unplottedFloorDevicesList.splice(i, 1);
        }

        $this.floorDevicesList.forEach((floorDevice, key) => {
          if (floorDevice.id === $this.activeMoveableElementId) {
            // var $currentMoveableElement = document.getElementById($this.activeMoveableElementId + "_section");
            var $currentMoveableElement = document.getElementsByClassName(
              $this.activeMoveableElementId + "_section"
            )[0];
            floorDevice.coords = $this.getActiveMoveableCoordinates();

            var plottedDeviceId = [];
            Object.entries($this.plottedFloorDevicesList).forEach(
              ([key, plottedDevice]) => {
                plottedDeviceId.push(plottedDevice.id);
              }
            );
            if (!plottedDeviceId.includes($this.activeMoveableElementId)) {
              floorDevice.current_coords =
                $this.getFloorDeviceCurrentCoordinates(
                  floorDevice.coords,
                  false
                );
            }

            floorDevice.device_type =
              $this.activeMoveableElementId.split("_")[0];
            ``;
            $this.$set(
              $this.plottedFloorDevicesList,
              floorDevice.id,
              floorDevice
            );
          }
        });

        // console.log(
        //   "updateFloorplanDevices -- JSON.parse(JSON.stringify($this.plottedFloorDevicesList))"
        // );
        // console.log(JSON.parse(JSON.stringify($this.plottedFloorDevicesList)));

        $this.updateFloorplanDevices({
          devices: JSON.parse(JSON.stringify($this.plottedFloorDevicesList))
        });
        // console.log($this.unplottedFloorDevicesList)

        $this.islistenReleaseActiveMoveableElementId = false;
        $this.activeMoveableElementId = null;
        $this.isMoveableOutsideDropzone = false;
        // console.log($this.unplottedFloorDevicesList)
      } else {
        $this.removeDeviceFromFloorplan();
      }
    },
    removeDeviceFromFloorplan: function () {
      var $this = this;

      if (!$this.isMoveableUnplotted) {
        $this.$bvModal
          .msgBoxConfirm("Do you want to return this device as unplotted?", {
            centered: true
          })
          .then((value) => {
            if (value == true) {
              if (
                $this.plottedFloorDevicesList[$this.activeMoveableElementId]
              ) {
                $this.unplottedFloorDevicesList.push(
                  $this.plottedFloorDevicesList[$this.activeMoveableElementId]
                );
                delete $this.plottedFloorDevicesList[
                  $this.activeMoveableElementId
                ];
              } else {
                let i = $this.unplottedFloorDevicesList
                  .map((item) => item.id)
                  .indexOf($this.activeMoveableElementId);
                let device = $this.unplottedFloorDevicesList[i];
                $this.unplottedFloorDevicesList.splice(i, 1);
                device.pos = null;
                $this.unplottedFloorDevicesList.push(device);
              }
              Array.from(
                document.getElementsByClassName("unplotted-device-icon")
              ).forEach(function (element, index, array) {
                element.style.transform = "";
              });

              $this.activeMoveableElementId = null;
              $this.islistenReleaseActiveMoveableElementId = false;
              $this.isMoveableOutsideDropzone = false;
              $this.updateFloorplanDevices({
                devices: JSON.parse(
                  JSON.stringify($this.plottedFloorDevicesList)
                )
              });
            }
          })
          .catch((err) => {
            // console.log(err);
            // An error occurred
          });
      } else {
        if ($this.plottedFloorDevicesList[$this.activeMoveableElementId]) {
          $this.unplottedFloorDevicesList.push(
            $this.plottedFloorDevicesList[$this.activeMoveableElementId]
          );
          delete $this.plottedFloorDevicesList[$this.activeMoveableElementId];
        } else {
          let i = $this.unplottedFloorDevicesList
            .map((item) => item.id)
            .indexOf($this.activeMoveableElementId);
          let device = $this.unplottedFloorDevicesList[i];
          // $this.unplottedFloorDevicesList.splice(i, 1);
          // $this.unplottedFloorDevicesList.push(device)
        }

        $this.activeMoveableElementId = null;
        $this.islistenReleaseActiveMoveableElementId = false;
        $this.isMoveableOutsideDropzone = false;
        // $this.updateFloorplanDevices({"devices": JSON.parse(JSON.stringify($this.plottedFloorDevicesList))});
      }
    },
    listenChangeActiveFloorplan: function (e) {
      var $this = this;
      $this.changeActiveFloorplan($this.activeFloorplanID);
    },
    setActiveMoveableId: function (id) {
      var $this = this;
      if (!$this.isEditMode) {
        return;
      }
      if ($this.activeMoveableElementId) {
        $this.releaseActiveMoveableElement();
      }
      $this.activeMoveableElementId = id;
      setTimeout(() => {
        $this.islistenReleaseActiveMoveableElementId = true;
      }, 100);
    },
    // onFloorPlanLayoutEditMode: function (e) {
    //     var $this = this;
    //     $this.isEditMode = true
    // },
    // offFloorPlanLayoutEditMode: function (e) {
    //     var $this = this;
    //
    //     if ($this.activeMoveableElementId !== null) {
    //         $this.$store.dispatch('session/addGlobalAlertMessage', { "message_text": 'You are still editing a device', "message_type": "danger" });
    //         return ;
    //     }
    //
    //     $this.isEditMode = false
    //     $this.activeMoveableElementId = null
    // },
    toggleFloorPlanLayoutEditMode: function (e) {
      var $this = this;
      if (!$this.isEditMode) {
        if ($this.activeMoveableElementId !== null) {
          $this.isEditMode = true;
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "You are still editing a device",
            message_type: "danger"
          });
          return;
        }

        $this.activeMoveableElementId = null;
      }
    },
    isWithinFloorplanImage: function () {
      var $this = this;
      // var parentPos = $this.$refs.floorplanImageContainer;
      var parentPos = $this.$refs.floorplanImage.getBoundingClientRect();
      // console.log($this.activeMoveableElementId + "_section")
      // console.log(document.getElementsByClassName($this.activeMoveableElementId + "_section"))
      var childPos = document
        .getElementsByClassName($this.activeMoveableElementId + "_section")[0]
        .getBoundingClientRect();
      // console.log(parentPos.left)
      // console.log(childPos)
      if (
        childPos.left < parentPos.left ||
        childPos.top < parentPos.top ||
        childPos.right > parentPos.right ||
        childPos.bottom > parentPos.bottom
      ) {
        return false;
      }

      return true;
    },
    getActiveMoveableElement: function () {
      var $this = this;
      return document
        .getElementsByClassName($this.activeMoveableElementId + "_section")[0]
        .getElementsByTagName("i");
    },
    handleDrag({ target, transform }) {
      var $this = this;
      // var $currentMoveableElement = document.getElementsByClassName($this.activeMoveableElementId + "_section");
      // $this.activeMoveableElement['original_position'] = {
      //     "left": target.style.left,
      //     "top": target.style.top,
      // }
      // console.log($this.activeMoveableElement);

      $this.hoverDropzone = true;
      $this.isMoveableOnDrag = true;
      $this.isMoveableUnplotted = false;
      target.style.transform = transform;

      var target_pos = target.getBoundingClientRect();
      // console.log(target_pos.x, target_pos.y, "vs", $this.unplottedContainer)
      if (
        target_pos.x < $this.unplottedContainer.left ||
        target_pos.x > $this.unplottedContainer.right ||
        target_pos.y < $this.unplottedContainer.top ||
        target_pos.y > $this.unplottedContainer.bottom
      ) {
        $this.isMoveableOutsideDropzone = true;
      } else {
        $this.isMoveableOutsideDropzone = false;
      }
    },
    handleResize({ target, width, height }) {
      var $this = this;
      // console.log("onResize", width, height);
      target.style.width = `${width}px`;
      target.style.height = `${height}px`;
    },
    handleScale({ target, transform }) {
      var $this = this;
      // console.log("onScale", transform);
      target.style.transform = transform;
    },
    handleRotate({ target, transform }) {
      var $this = this;
      // console.log("onRotate", transform);
      target.style.transform = transform;
    },
    handleWarp({ target, transform }) {
      var $this = this;
      // console.log("onWarp", transform);
      target.style.transform = transform;
    },
    clearAllStates() {
      Object.keys(this.states).forEach((key) => {
        this.moveable[key] = false;
      });
    },
    onGlobalMouseDown() {
      var $this = this;
      $this.isMoveableOnDrag = false;
    },
    confirmDeleteFloorPlan: function (e) {
      e.preventDefault();
      var $this = this;
      $this.$bvModal
        .msgBoxConfirm(
          "Confirm deleting this floor plan image for " + $this.floorName + "?",
          {
            centered: true
          }
        )
        .then((value) => {
          if (value == true) {
            var API_URL =
              $this.getAPIServerURL +
              "/floorplans/buildings/" +
              $this.buildingID +
              "/floors/" +
              $this.floorID +
              "/floorplans/" +
              $this.activeFloorplanID +
              "/";
            const client = $this.$root.getAjaxFetchClient();
            client
              .deleteRequest(API_URL)
              .then((data) => {
                $this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: "Deleted floor plan",
                  message_type: "success"
                });
                $this.activeFloorplanID = null;
                $this.activeFloorplanObj = {};
                $this.fetchFloorPlans();
                // $this.$store.dispatch('psim/updateFloor', { "building_id": $this.buildingID, "floor_id": $this.floorID, "data": { "img": null } });

                // TOCLEAN
                // } else {
                //     $this.$store.dispatch('session/addGlobalAlertMessage', { "message_text": data.detail, "message_type": "danger" });
                // }
              })
              .catch((err) => {
                $this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: err.detail,
                  message_type: "danger"
                });
                if (err.status == 401) {
                  $this.$store.dispatch("session/logoutSession");
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    activeMoveableElementLabelClass: function (device_id) {
      // console.log(this.isMoveableOutsideDropzone)
      if (
        this.activeMoveableElementId == device_id &&
        this.isMoveableOutsideDropzone
      ) {
        return false;
      }
      // console.log(device_id)
      return true;
    }
  }
};
</script>

<style lang="scss">
@import "./SettingsFloorplan.scss";
</style>
